import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import DrugTestTypes from "../../../components/Landers/DrugTestTypes";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";

const steps = [
	{
		h5: "Collect Samples",
		p:
			"A Phlebotomist visits the candidate to draw samples to conduct initial drug screening to determine the existence of a detectable drug.",
	},
	{
		h5: "Initiate Drug Screening and Confirmation",
		p:
			"In case a candidate is tested positive, a medical professional further reviews the drug report to confirm the diagnosis.",
	},
	{
		h5: "Review Results",
		p:
			"Make smarter hiring decisions with our in-depth drug screening report.",
	},
];
const testimonials = [
	{
		name: "Rupasi M",
		company: "Datarynx Pvt. Ltd.",
		p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
	},
	{
		name: "Ravindra M",
		company: "Rupeezen",
		p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
	},
	{
		name: "Maryam J",
		company:
			"Resolvit Management and Technology Consulting India Pvt. Ltd.",
		p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
	},
];

export default () => (
	<div className={styles.solutions}>
		<SEO
			title="Pre-Employment Drug test and Screening Service | SpringVerify India"
			desc="Maintain a safe and secure workplace by conducting a pre-employment drug screening with SpringVerify to hire at scale effortlessly."
			url="https://in.springverify.com/screenings/drug-test-verification/"
			imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(Drug+Test).jpg"
		/>
		<div className={styles.topSection}>
			<Header />
			<Banner
				h1="Pre-Employment Drug Screening for a Safe and Secure Workplace"
				p="Run a comprehensive pre-and post-hire drug screening to help your company stay compliant and meet work policy requirements."
				img="https://assets-sv-in.gumlet.io/LP/Drug/banner.png"
			/>
		</div>
		<Brands />
		<section>
			<SectionTemplate
				img="Drug/need.svg"
				h2="Need for Employee Drug Screening"
				p={`Drug screening helps employers identify if a candidate has been under an influence of drugs or any sort of performance enablers. Drug screening will help you to:`}
				ul={[
					"Maintain a safe and secure work environment free of toxicity",
					"Identify the use of drugs early on by employees",
					"Prevent drug usage by employees on the job",
					"Avoid the cost of bad hires",
				]}
			/>
		</section>
		<ThreeSteps
			steps={steps}
			candidate={"https://assets-sv-in.gumlet.io/LP/Drug/candidate.png"}
			path="Drug"
		/>
		<DrugTestTypes />
		<WhatsUnique
			path="Employment"
			info={[
				"Easy onboarding",
				"Safe and secure",
				"Transparent pricing",
				"Workflow automation",
				"Remote-friendly and contactless verifications",
				"Compliant with ISO 27001, PCI, and more",
				"Tailored solutions based on your requirements",
				"Powered by AI and Blockchain",
			]}
		/>
		<Testimonials testimonials={testimonials} path="Employment" />
		<GetStarted
			img="Drug/get-started.svg"
			h3="Get started with reliable\nDrug Screening now"
			p="Foster a drug-free environment for your people to thrive"
		/>
		<Footer />
	</div>
);
