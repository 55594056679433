import React from "react";
import styles from "../../components/Landers/landers.module.scss";
import styles1 from "../../components/Landers/drug.module.scss";
import IsMobile from "./IsMobile";

const drugs = [
	["PCP (PhenCyclidine)", 1, 1],
	["Barbiturates", 0, 1],
	["Methadone", 0, 1],
	["Benzodiazepines", 0, 1],
	["Morphine / Opiates", 1, 1],
	["Amphetamines", 1, 1],
	["Propoxyphene", 0, 1],
	["Cocaine", 1, 1],
	["Methaqualone", 0, 1],
	["Cannabis / THC / Marijuana", 1, 1],
];

export default () => (
	<section className={styles1.drug}>
		<h2>Types of Drug Tests</h2>
		<p>
			SpringVerify offers employers two unique type of Drug Tests which
			uncover the
			<br />
			presence of major controlled substances.
		</p>
		<table>
			<thead>
				<tr>
					<th></th>
					<th>Drug Screen</th>
					<th>5 Panel</th>
					<th>10 Panel</th>
				</tr>
			</thead>
			<tbody>
				{drugs.map((drug, ind) => (
					<tr>
						{drug.map((d, i) => (
							<>
								{d === 0 ? (
									<td>
										<img
											src="https://assets-sv-in.gumlet.io/LP/Drug/cross.svg"
											alt=""
										/>
									</td>
								) : d === 1 ? (
									<td>
										<img
											src="https://assets-sv-in.gumlet.io/LP/Drug/tick.svg"
											alt=""
										/>
									</td>
								) : (
									<>
										<td className="img">
											{!IsMobile() && (
												<img
													src={`https://assets-sv-in.gumlet.io/LP/Drug/Types/${
														ind + 1
													}.png`}
													alt=""
												/>
											)}
										</td>
										<td>
											<>{d}</>
										</td>
									</>
								)}
							</>
						))}
					</tr>
				))}
			</tbody>
		</table>
	</section>
);
